import { getFeatureFlagValue } from './helpers';
import { ICommonFeatures } from './interface';

export const commonFeatures: ICommonFeatures = {
    isCA9685_localAuthLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA9685_LOCAL_AUTH_LOGIN_SCREEN,
    ),
    isCA11388On_TermsOfServiceLoginScreen: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA11388_TERMS_OF_SERVICE_LOGIN_SCREEN,
    ),
    isCA10347_useAppLoaderHookOn: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_CA10347_ADD_USE_APP_LOADER_HOOK,
    ),
    enableAuth0Option: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_ENABLE_AUTH0_OPTION,
    ),
    newLoginMessage: getFeatureFlagValue(
        process.env.REACT_APP_FEATURE_NEW_LOGIN_MESSAGE,
    ),
    isCA12555On_ScaleTicketCommodityDropdownBugFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12555_SCALE_TICKET_COMMODITY_DROPDOWN_BUG_FIX,
    ),
    isCA12603_AutoCompleteDropDownTabFocusFix: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12603_AUTO_COMPLETE_DROP_DOWN_TAB_FOCUS_FIX,
    ),
    isCA12793On_CloseAutocompleteDropdownOnTabThrough: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12793_CLOSE_AUTOCOMPLETE_DROPDOWN_ON_TAB_THROUGH,
    ),
    isCA12732_AutoCompleteDropDownCloseShiftTabList: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA12732_AUTO_COMPLETE_DROP_DOWN_CLOSE_SHIFT_TAB_LIST,
    ),
    isCA13229On_AutocompleteAllowsNonOptionValues: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13229_AUTOCOMPLETE_ALLOWS_NON_OPTION_VALUES,
    ),
    isCA13259On_AutocompleteOptionHighlightCorrection: getFeatureFlagValue(
        process.env
            .REACT_APP_FEATURE_CA13259_AUTOCOMPLETE_OPTION_HIGHLIGHT_CORRECTION,
    ),
};
